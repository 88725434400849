import React from 'react';

import { IGatsbyImageData } from "gatsby-plugin-image";
import FeaturedImage from "@js/component/FeaturedImage";
import Heading from "@js/component/Heading";
import Box from "@js/component/Box";
import BoxCompare from "@js/component/BoxCompare";
import Main from "@js/component/Main";
import TagIcon from "@js/component/icon/TagIcon";
import {Link} from "gatsby";
import NewsCards from "@js/component/NewsCards";
import clsx from "clsx";
import CalendarIcon from "@js/component/icon/CalendarIcon";
import {Helmet} from "react-helmet";
import UserEditIcon from "@js/component/icon/UserEditIcon";

import * as classNames from "@css/component/template/wordpress/Page.module.scss";
import BreadCrumbs from "@js/component/BreadCrumbs";


/**
 * @type PostProps
 */
type PostProps = {
    pageContext: {
        author: {
            node: {
                name: string;
            }
        },
        content: string;
        date: string;
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    publicURL: string;
                };
                altText: string;
                height: number;
                mimeType: string;
                sourceUrl: string;
                width: number;
            }
        },
        id: number;
        slug: string;
        tags: {
            nodes: {
                id: number;
                link: string;
                name: string;
                slug: string;
            }[]
        },
        seo: {
            breadcrumbs: {
                text: string;
                url: string;
            }[];
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            title: string;
            twitterDescription: string;
            twitterTitle: string;
            schema: {
                raw: string;
            }
        };
        title: string;
        uri: string;
        posts: [];
    };
};

/**
 * @const PostData
 */
const PostData = (props: PostProps) => {
    const {
        pageContext
    } = props;

    const ArticleStructuredData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: pageContext.title,
        image: pageContext.featuredImage?.node.localFile.publicURL,
        datePublished: pageContext.seo.opengraphPublishedTime,
        dateModified: pageContext.seo.opengraphModifiedTime ? pageContext.seo.opengraphModifiedTime : "",
        author: {
            "@type": "Person",
            name: pageContext.author.node.name
        }
    };

    const OpenGraphImage = process.env.SITE_URL + pageContext.featuredImage?.node.localFile.publicURL;

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>{ pageContext.title }</title>

                { pageContext.seo.metaKeywords && <meta content={ pageContext.seo.metaKeywords } name="keywords" /> }
                { pageContext.seo.metaDesc && <meta content={ pageContext.seo.metaDesc } name="description" /> }

                <meta content={ `${ pageContext.seo.metaRobotsNofollow }, ${ pageContext.seo.metaRobotsNoindex }` } name="robots" />

                { pageContext.seo.opengraphDescription && <meta content={ pageContext.seo.opengraphDescription } property="og:description" /> }
                { pageContext.seo.opengraphModifiedTime && <meta content={ pageContext.seo.opengraphModifiedTime } property="article:modified_time" /> }
                { pageContext.seo.opengraphPublishedTime && <meta content={ pageContext.seo.opengraphPublishedTime } property="article:published_time" /> }
                { pageContext.author.node.name && <meta content={ pageContext.author.node.name } property="article:author" /> }
                { pageContext.seo.opengraphSiteName && <meta content={ pageContext.seo.opengraphSiteName } property="og:site_name" /> }
                { pageContext.seo.opengraphTitle && <meta content={ pageContext.seo.opengraphTitle } property="og:title" /> }
                { pageContext.seo.opengraphType && <meta content={ pageContext.seo.opengraphType } property="og:type" /> }
                { pageContext.seo.opengraphUrl && <meta content={ pageContext.seo.opengraphUrl } property="og:url" /> }

                <meta property="og:image" content={ OpenGraphImage } />
                <meta property="og:image:type" content={ pageContext.featuredImage?.node.mimeType } />
                <meta property="og:image:width" content={ pageContext.featuredImage?.node.width.toString() } />
                <meta property="og:image:height" content={ pageContext.featuredImage?.node.height.toString() } />
                <meta property="og:image:alt" content={ pageContext.featuredImage?.node.altText } />

                { pageContext.seo.twitterDescription && <meta content={ pageContext.seo.twitterDescription } name="twitter:description" /> }
                { pageContext.seo.twitterTitle && <meta content={ pageContext.seo.twitterTitle } name="twitter:title" /> }

                <script type="application/ld+json" className="yoast-schema-graph">
                    { pageContext.seo.schema.raw }
                </script>
                <script type="application/ld+json">
                    { JSON.stringify(ArticleStructuredData ) }
                </script>
            </Helmet>
            <FeaturedImage
                className={ classNames.featuredImage }
                gatsbyImageData={ pageContext.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
            />
            <div className={ classNames.container }>
                <article className={ classNames.article }>
                    { pageContext.seo.breadcrumbs &&
                        <BreadCrumbs
                            breadCrumbs={ pageContext.seo.breadcrumbs.slice(1) }
                            variant="labelNewsSingle"
                            parents="labelnieuws"
                        />
                    }
                    <Heading className={ classNames.heading } element="h1" variant="large">
                        { pageContext.title }
                    </Heading>
                    <div className={ classNames.subHeader }>
                        <span className={ classNames.date }>
                            <CalendarIcon className={ classNames.icon }/>
                            { pageContext.date }
                        </span>
                        <span className={ classNames.author }>
                            <UserEditIcon className={ classNames.icon }/>
                            { pageContext.author.node.name }
                        </span>
                    </div>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box className={ classNames.box }>
                                <div dangerouslySetInnerHTML={{ __html: pageContext.content }}></div>
                                { pageContext.tags.nodes.length > 0 &&
                                    <div>
                                        <p className={ classNames.tagsWrapper }>
                                            <TagIcon className={ classNames.icon } />
                                            { pageContext.tags.nodes.map(item =>
                                                <Link key={ item.id } to={`/labelnieuws/tag/${item.slug}`} className={ classNames.tag }>{ item.name }</Link>
                                            )}
                                        </p>
                                    </div>
                                }
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <BoxCompare />
                        </div>
                    </div>
                    { (pageContext.posts.length - 1) > 0 &&
                    <>
                        <Heading className={ clsx(classNames.heading, classNames.extraMargin) } element="h2" variant="large">
                            Meer labelnieuws
                        </Heading>
                        <NewsCards
                            posts={ pageContext.posts.slice(0, 4) }
                            excludeId={ pageContext.id }
                            variant="extraNews"
                        />
                    </>
                    }
                </article>
            </div>
        </Main>
    );
}

export default PostData;